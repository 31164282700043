<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("General") }}</span>
      </template>

      <account-setting-general
        v-if="userData"
        :user-data="userData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("Change Password") }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '@/views/pages/user/userStoreModule'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      userData: null,
    }
  },
  created() {
    store.dispatch('user/fetchUser', { id: useJwt.getLoggedUserId() })
      .then(response => { this.userData = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          this.userData = undefined
        }
      })
  },
}
</script>
