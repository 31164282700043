var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Old Password","label-for":"account-old-password"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Old Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-old-password","name":"old-password","type":_vm.passwordFieldTypeOld,"placeholder":_vm.$t('Old Password')},model:{value:(_vm.passwordValueOld),callback:function ($$v) {_vm.passwordValueOld=$$v},expression:"passwordValueOld"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"new-password","label":_vm.$t('New Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('New Password'),"rules":"required","vid":"new-password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{ref:"new-password",attrs:{"id":"new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":_vm.$t('New Password')},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=$$v},expression:"newPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"retype-password","label":_vm.$t('Retype New Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Retype New Password'),"rules":"required|confirmed:new-password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"retype-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","placeholder":_vm.$t('Retype New Password')},model:{value:(_vm.retypePassword),callback:function ($$v) {_vm.retypePassword=$$v},expression:"retypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.changePassword}},[_vm._v(" "+_vm._s(_vm.$t("Save Changes"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }