<template>
  <b-card>
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="saveData"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">

            <b-form-group
              :label="$t('Name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.name"
                  name="name"
                  :placeholder="$t('Name')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-group
                label="E-mail"
                label-for="mail"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="invalid"
              type="submit"
            >
              {{ $t("Save changes") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t("Reset") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    ValidationProvider,

  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.userData)),
      profileFile: null,
      required,
      email,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.userData))
    },
    saveData() {
      this.$refs.form.validate()
        .then(success => {
          if (success) {
            this.$store.dispatch('user/updateUser', this.optionsLocal)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Account settings updated',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                console.log(error)

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error updating account settings',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
